





































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'

import draggable from 'vuedraggable/dist/vuedraggable.umd.js'
import {CoursePermission} from '~/utils/permissions'

@Component({
    components: {
        draggable
    },
    metaInfo() {
        return {
            title: 'Course Tag'
        }
    }
})

export default class CourseTags extends Vue {
    static permission = CoursePermission.CourseTag

    categories: string[] = []
    selectedCategory: string = ''

    tags: { tag: string }[] = []
    rawTags: string[] = []

    input: string = ''
    showInput: boolean = false
    async created() {
        const res = await createRequest(`/courses/tags/categories`, 'get').send()
        this.categories = res.data.categories

        this.selectedCategory = this.$route.query['category'] as string || this.categories[0]
        await this.updateCategory()
    }
    async updateCategory() {
        const res = await createRequest(`/courses/${this.selectedCategory}/tags/admin`, 'get').send()
        this.rawTags = res.data.tags
        this.tags = this.rawTags.map((t) => {
            return {tag: t}
        })

        this.$router.push({query: this.getQuery()})
    }

    inputCheck() {
        this.input = this.input.replace(/\t/g, '\n')
    }
    async submit() {
        const newTags = this.input.split('\n').map((str: string) => {
            return str.trim()
        })
        this.showInput = false
        this.input = ''
        if (newTags.length > 0 && newTags[0] !== '') {
            this.rawTags.push(...newTags)
            this.tags.push(...newTags.map((t) => {
                return {tag: t}
            }))
        }

        await createRequest(`/courses/${this.selectedCategory}/tags`, 'put', {}, {
            tags: this.rawTags
        }).send()

        await this.updateCategory()
    }
    async remove(tag: string) {
        this.$confirm(`Remove tag ${tag} of ${this.selectedCategory}?`, 'Alert', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (data) => {
            const i = this.rawTags.indexOf(tag)
            this.rawTags.splice(i, 1)
            this.tags.splice(i, 1)

            await createRequest(`/courses/${this.selectedCategory}/tags`, 'put', {}, {
                tags: this.rawTags
            }).send()

            await this.updateCategory()
        }).catch(() => {
        })
    }

    private

    getQuery() {
        return {category: this.selectedCategory}
    }
}

